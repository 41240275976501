<template>
  <section>
    <div class="corevalue-grid">
      <a href="/hayche-digital">
        <div class="grid-item">
          <img
            src="https://res.cloudinary.com/hayche-network/image/upload/v1646928345/website/images/services/digital-solutions_ka8cha.png"
            alt="digital-solutions"
          />
          <p>DIGITAL SOLUTIONS</p>
        </div>
      </a>

      <div class="grid-item">
        <img
          src="https://res.cloudinary.com/hayche-network/image/upload/v1646928346/website/images/services/hotel-operations_lp5v9o.png"
          alt="hotel-operations"
        />
        <p>HOTEL OPERATIONS</p>
      </div>

      <div class="grid-item">
        <img
          src="https://res.cloudinary.com/hayche-network/image/upload/v1646928345/website/images/services/human-resources_qpyldl.png"
          alt="human-resources"
        />
        <p>HUMAN RESOURCES</p>
      </div>
    </div>
  </section>
</template>


<style scoped>
.corevalue-grid {
  margin-top: 50px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  place-items: center;
  width: 100%;
  column-gap: 27px;
  gap: 30px;
  width: 100%;
}

.corevalue-grid .grid-item {
  border: none;
  box-sizing: border-box;
  width: 90%;
  margin: 0 auto;
  display: grid;
  place-items: center;
}

.corevalue-grid .grid-item img {
  object-fit: cover;
  height: 250px;
  width: 250px;
  border-radius: var(--border-radius);
  margin-bottom: 15px;
}

.corevalue-grid .grid-item p {
  font-style: normal;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  font-family: "Optima";
}
</style>