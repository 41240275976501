<template>
  <main class="navigation">
    <header class="desktop-nav">
      <div class="header__container">
        <div class="header__left">
          <a href="/">
            <img
              class="logo"
              src="https://res.cloudinary.com/hayche-network/image/upload/v1646928360/website/logo/logo_brei8m.svg"
              alt="Logo"
            />
          </a>
        </div>
        <div class="header__right">
          <nav>
            <ul class="nav__links">
              <li class="dropdown">
                <a>
                  Services
                  <span class="material-icons more">expand_more</span>
                  <span class="material-icons less">expand_less</span>
                </a>
                <div class="dropdown__container">
                  <div class="dropdown__content services">
                    <service_menu />
                  </div>
                </div>
              </li>

              <li class="dropdown">
                <a href="https://academy.hayche.net">Academy</a>
              </li>
              <li class="dropdown">
                <a href="/hayche-digital">Digital</a>
              </li>

              <li class="dropdown">
                <a>
                  Lifesyle
                  <span class="material-icons more">expand_more</span>
                  <span class="material-icons less">expand_less</span>
                </a>
                <div class="dropdown__container">
                  <div class="dropdown__content services">
                    <lifestyle_menu />
                  </div>
                </div>
              </li>

              <li class="dropdown">
                <a href="/network">Network</a>
              </li>
              <li class="dropdown">
                <a href="https://events.hayche.net/events/" target="_blank"
                  >Events</a
                >
              </li>
              <li class="dropdown">
                <a href="/about">About us</a>
              </li>
              <a href="/account-gateway" class="nav__cta__2">
                <button>Login</button>
              </a>
            </ul>
          </nav>
          <a href="#" class="nav__menu" @click="openNav()">
            <img src="@/assets/icons/menu-icon.svg" />
          </a>
        </div>
      </div>
    </header>
    <div id="mobile__menu" class="mobile-nav overlay">
      <a href="#" class="close" @click="closeNav()">&times;</a>
      <div class="overlay__content">
        <ul class="mobile__links">
          <li class="mobile-dropdown">
            <label for="btn-1" class="show">Services</label>
            <input type="checkbox" id="btn-1" />
            <div class="mobile-dropdown__container nav1">
              <div class="mobile-dropdown__content">
                <a href="/hayche-digital">Digital Solutions</a>
                <a href="#">Hotel Operations</a>
                <a href="#">Human Resources</a>
              </div>
            </div>
          </li>
          <li class="dropdown">
            <a href="https://academy.hayche.net">Academy</a>
          </li>
          <li class="dropdown">
            <a href="/hayche-digital">Digital</a>
          </li>
          <li class="mobile-dropdown">
            <label for="btn-2" class="show">Lifestyle</label>
            <input type="checkbox" id="btn-2" />
            <div class="mobile-dropdown__container nav1">
              <div class="mobile-dropdown__content">
                <a href="https://blog.hayche.net">Blogs</a>
                <a href="#">Reviews</a>
              </div>
            </div>
          </li>
          <li class="dropdown">
            <a href="/network">Network</a>
          </li>
          <li class="dropdown">
            <a href="https://events.hayche.net/events/" target="_blank"
              >Events</a
            >
          </li>
          <li class="dropdown">
            <a href="/about">About us</a>
          </li>
        </ul>
        <div class="mobile-cta">
          <a href="/account-gateway" class="nav__cta__2">
            <button>Login</button>
          </a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import service_menu from "@/components/service-menu.vue";
import lifestyle_menu from "@/components/lifestyle-menu.vue";

export default {
  components: {
    service_menu,
    lifestyle_menu,
  },
  data() {
    return {};
  },
  methods: {
    openNav() {
      document.getElementById("mobile__menu").style.width = "100%";
    },

    closeNav() {
      document.getElementById("mobile__menu").style.width = "0";
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@media (min-width: 1024px) {
  #mobile__menu {
    display: none;
  }
}

.navigation {
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.94);
  z-index: 9999;
}
.navigation li a {
  font-size: 12px !important;
}

.navigation label {
  font-size: 12px !important;
}

.navigation::before {
  content: "";
  margin: -35px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

ul {
  list-style: none;
}

li,
a {
  color: black;
  text-decoration: none;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.header__container {
  display: flex;
  max-width: 80%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.logo {
  cursor: pointer;
  width: 60px;
}

.cta {
  padding: 0 20px;
}

.header__right a {
  padding: 20px 5px;
  display: flex;
  font-size: 14px;
  align-items: center;
  margin: 0 10px;
  transition: all 0.1s linear;
}

li.dropdown > a {
  border-bottom: 2px solid transparent;
}

.header__right a {
  position: relative;
}
.header__right .dropdown a:hover::after {
  content: "";
  background: var(--primary-hayche);
  width: 6px;
  height: 6px;
  border-radius: 50%;

  position: absolute;
  left: 50%;
  top: 50px;
}

.header__right div.dropdown__content a:hover {
  border: none;
}

.header__right a.nav__cta,
.header__right a.nav__menu {
  border-bottom: 2px solid transparent;
}

.nav__menu img {
  width: 20px;
  filter: invert();
}

.header__right a.nav__cta:hover,
.header__right a.nav__menu:hover {
  border-bottom: 3px solid transparent;
}

.header__right {
  display: flex;
  align-items: center;
}

.nav__links {
  display: flex;
  margin: 5px 5px;
  align-items: center;
}

.nav__links a span {
  display: inline-block;
  font-size: 14px;
  margin-left: 4px;
}

.nav__links a span.less {
  display: none;
}

.dropdown__container {
  display: none;
  position: absolute;
  background-color: var(--secondary);
  width: 100%;
  left: 0;
  background-color: white;
  z-index: 60;
}

.dropdown__content {
  max-width: 1100px;
  margin: 0 auto;
}

.dropdown__container a {
  color: black;
}

.dropdown:hover a span.less {
  display: block !important;
}

.dropdown:hover a span.more {
  display: none !important;
}

.dropdown:hover .dropdown__container {
  display: block;
}

a.nav__menu {
  display: none;
}

.nav__cta__1 button {
  padding: 10px;
  min-width: 150px;
  width: 100%;
  background-color: var(--primary-hayche);
  border: none;
  color: white;
  border-radius: var(--button-border-radius);
  cursor: pointer;
  font-size: 16px;
}

.nav__cta__1 button:hover {
  background-color: rgb(52, 52, 52);
}

.nav__cta__2 button {
  padding: 10px;
  min-width: 150px;
  width: 100%;
  background-color: rgb(23, 23, 23);
  border: none;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.168);
  border-radius: var(--button-border-radius);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: 0.5s, transform 0.5s;
}

.nav__cta__2 button:hover {
  border: 1px soild rgb(55, 55, 55);
  transition: 0.5s, transform 0.5s;
  background: transparent;
  color: black;
  border: 1px solid black;
}

/* .mobile-nav .show, */
.mobile-nav input {
  display: none;
}

@media screen and (max-width: 1024px) {
  ul.nav__links,
  a.nav__cta {
    display: none;
  }

  a.nav__menu {
    display: inherit;
  }

  .overlay a {
    font-size: 20px;
  }

  .mobile__links .less {
    display: none;
  }

  .mobile-dropdown__container {
    margin-top: 20px;
    width: 100%;
    left: 0;
    z-index: 60;
  }

  .mobile-dropdown__container a {
    display: block;
  }

  .mobile-nav .show {
    display: block;
    cursor: pointer;
    font-size: 20px;
    color: white;
  }

  .mobile-dropdown__content a {
    color: white;
    margin-bottom: 1em;
    margin-left: 20px;
    font-size: 16px;
  }

  .mobile-dropdown__content a::before {
    content: "-";
    color: white;
    margin-right: 1em;
  }

  .mobile-nav .show:hover {
    color: rgb(184, 184, 184);
  }

  .show + a,
  .mobile-dropdown__container {
    display: none;
  }

  [id^="btn"]:checked + .mobile-dropdown__container {
    display: block;
  }
}

/* Mobile Nav */
.overlay {
  min-height: 100%;
  width: 0;
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  background-color: rgb(49, 49, 49);
  overflow-x: hidden;
  transition: all 0.5s ease;
}

.overlay__content::-webkit-scrollbar {
  display: none;
}

.overlay__content {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 1024px) {
  #mobile__menu > div > ul > li > a {
    color: white;
  }

  .overlay__content {
    margin-top: 100px;
  }
}

.mobile__links,
.mobile-cta {
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 15px;
  width: 80%;
}

.mobile-cta button {
  margin-top: 15px;
}

#mobile__menu > div > ul > li {
  padding-top: 15px;
  display: block;
}

.mobile-cta .nav__cta {
  display: block;
  padding: 15px;
}

#mobile__menu > div > ul > li > a {
  display: flex;
  align-items: center;
}

.overlay a {
  transition: all 0.3s ease;
}

.overlay a:hover,
.overlay:focus {
  color: #0088a9;
}

.overlay .close {
  position: absolute;
  top: 5px;
  right: 25px;
  font-size: 30px;
  color: white;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 16px;
  }

  .overlay .close {
    font-size: 30px;
    top: 15px;
    right: 35px;
  }
}

/* learning path */
</style>
