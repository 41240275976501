<template>
  <section>
    <div class="lifestyle-grid">
      <a href="https://blog.hayche.net">
        <div class="lifestyle-item">
          <div>
            <img
              src="https://res.cloudinary.com/hayche-network/image/upload/v1646928326/website/images/lifestyle/blog_mbrfwn.png"
              alt="hayche blog"
            />
            <p>Blogs</p>
          </div>
        </div>
      </a>

      <a href="/reviews">
        <div class="lifestyle-item">
          <div>
            <img
              src="https://res.cloudinary.com/hayche-network/image/upload/v1646928327/website/images/lifestyle/reviews_rpmk5f.png"
              alt="hayche reviews"
            />
            <p>Reviews</p>
          </div>
        </div>
      </a>
    </div>
  </section>
</template>


<style scoped>
.lifestyle-grid {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
  padding: 100px;
}

.lifestyle-item {
  padding: 30px;
  background: #f8f8f8c6;
  border: 0.1px solid rgba(0, 0, 0, 0.086);
  box-sizing: border-box;
  box-shadow: 40px 5px 40px rgba(0, 0, 0, 0.075);
  border-radius: 100px;
  width: 300px;
  height: 150px;
  padding: 15px;
}

.lifestyle-item > div {
  display: grid;
  grid-template-columns: 45% 55%;
  width: 100%;
  height: 100%;
  gap: 10px;
  align-items: center;
}

.lifestyle-item img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
}

.lifestyle-item p {
  font-family: "Optima";
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 21px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(0, 0, 0, 0.7);
}
</style>