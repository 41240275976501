// layouts
import main from "@/layouts/main.vue";
import blank from "@/layouts/blank.vue";

// errors
const error404 = () => import("@/pages/404");

// landing
const Home = () => import("@/pages/home/main.vue");
const Network = () => import("@/pages/network/main.vue");
const HaycheDigital = () => import("@/pages/digital/main.vue");
const Reviews = () => import("@/pages/reviews/main.vue");
const About = () => import("@/pages/about/main.vue");
const TermsAndCondition = () => import("@/pages/t&c.vue");
const Privacy = () => import("@/pages/privacy.vue");
const Contact = () => import("@/pages/contact/main.vue");
const Account_Gateway = () => import("@/pages/account-gateway/main.vue");

const routes = [
  {
    path: "/",
    props: true,
    component: main,
    children: [
      // landing
      { name: "home", path: "/", component: Home },
      { name: "network", path: "/network", component: Network },
      { name: "reviews", path: "/reviews", component: Reviews },
      { name: "about", path: "/about", component: About },
      { name: "hayche-digital", path: "/hayche-digital", component: HaycheDigital },
      {
        name: "TermsAndConditions",
        path: "/t&c",
        component: TermsAndCondition,
      },
      { name: "privacy", path: "/privacy", component: Privacy },
      { name: "contact", path: "/contact", component: Contact },
    ],
  },

  {
    name: "account-gateway",
    path: "/account-gateway",
    component: Account_Gateway,
  },

  {
    path: "/:catchAll(.*)*",
    component: error404,
  },
];

export default routes;
